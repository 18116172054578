import axios from "axios";

const API_URL = process.env.REACT_APP_BASE_URL_API;
const token = `Bearer ` + localStorage.getItem('token');
const createdBy =  localStorage.getItem('userId');
const updatedBy =  localStorage.getItem('userId');
// const mailRoomId =  localStorage.getItem('mailRoomId');
// const branch =(localStorage.userDetails) ? JSON.parse(localStorage.userDetails).branch : "";

const config = {
  headers: {
    'Authorization': token,
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token'
  }
};
// --------------------Country -------------
const getCountry = async () =>{
    return axios.get(API_URL + "country/",  config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))
}
const deleteCountry = async (id:Number) =>{   
        return axios.delete(API_URL + "country/"+id,  config).then(
            (response) => {
                return response.data.message
            })
            .catch(error => console.log(error))
    
    }

const postCountry = async (countryName: String,countryCode: String) => {
        const bodyParamter = {
            countryName,
            countryCode,
            tCode: Date.now().toString(),
            countryStatus : 1,
            createdBy
                };
return axios.post(API_URL + "country/register", bodyParamter, config).then(
(response) => {
    return response.data.message
})
.catch(error => console.log(error))
    
}

const putCountry = async (countryName: String,countryCode: String, id:any) => {
    const bodyParamter = {
        countryName,
        countryCode,
        tCode: Date.now().toString(),
        countryStatus : 1,
        updatedBy
    };
    return axios.put(API_URL + "country/"+id, bodyParamter, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}

const getCountryById = async (id:any) => {

    return axios.get(API_URL + "country/"+id, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))
        
 
}
// -------------------------Country End ----------------

//----------------------- State -------------------------

const getState = async () =>{
    return axios.get(API_URL + "state/",  config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))
}
const deleteState = async (id:Number) =>{   
        return axios.delete(API_URL + "state/"+id,  config).then(
            (response) => {
                return response.data.message
            })
            .catch(error => console.log(error))
    
    }

const postState = async (stateName: String,stateCode: String, countryTableId :Number, tin : string) => {
        const bodyParamter = {
            stateName,
            stateCode,
            tin ,
            tCode: Date.now().toString(),
            stateStatus : 1,
            countryTableId,
            createdBy
                };
return axios.post(API_URL + "state/register", bodyParamter, config).then(
(response) => {
    return response.data.message
})
.catch(error => console.log(error))
    
}

const putState = async (stateName: String,stateCode: String, countryTableId :Number,  tin : string,  id:any) => {
    const bodyParamter = {
        stateName,
        stateCode,
        tin,
        tCode: Date.now().toString(),
        stateStatus : 1,
        countryTableId,
        updatedBy
    };
    return axios.put(API_URL + "state/"+id, bodyParamter, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}

const getStateById = async (id:any) => {

    return axios.get(API_URL + "state/"+id, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))
        
 
}
//----------------------- State End -----------------------
//----------------------- City -------------------------

const getCity = async () =>{
    return axios.get(API_URL + "city/",  config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))
}
const deleteCity = async (id:Number) =>{   
        return axios.delete(API_URL + "city/"+id,  config).then(
            (response) => {
                return response.data.message
            })
            .catch(error => console.log(error))
    
    }

const postCity = async (cityName: String, stateTableId :Number, countryTableId :Number) => {
        const bodyParamter = {
            cityName,
            stateTableId,
            countryTableId,
            tCode: Date.now().toString(),
            cityStatus : 1,
            createdBy
                };
return axios.post(API_URL + "city/register", bodyParamter, config).then(
(response) => {
    return response.data.message
})
.catch(error => console.log(error))
    
}

const putCity = async (cityName: String,  stateTableId :Number, countryTableId :Number,  id:any) => {
    const bodyParamter = {
        cityName,
        stateTableId,
        countryTableId,
        tCode: Date.now().toString(),
        cityStatus : 1,
        updatedBy
    };
    return axios.put(API_URL + "city/"+id, bodyParamter, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}

const getCityById = async (id:any) => {

    return axios.get(API_URL + "city/"+id, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))
        
 
}
//----------------------- City End -----------------------

//----------------------- Pincode -------------------------

const getPincode = async () =>{
    return axios.get(API_URL + "zipcode/",  config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))
}
const getPincodeList = async () =>{
    return axios.get(API_URL + "zipcode/forlist",  config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))
}
const deletePincode = async (id:Number) =>{   
        return axios.delete(API_URL + "zipcode/"+id,  config).then(
            (response) => {
                return response.data.message
            })
            .catch(error => console.log(error))
    
    }

const postPincode = async (zipCode: String, cityTableId :Number, stateTableId :Number, countryTableId :Number) => {
        const bodyParamter = {
            zipCode,
            cityTableId,
            stateTableId,
            countryTableId,
            tCode: Date.now().toString(),
            cityStatus : 1,
            createdBy
                };
return axios.post(API_URL + "zipcode/register", bodyParamter, config).then(
(response) => {
    return response.data.message
})
.catch(error => console.log(error))
    
}

const putPincode = async (zipCode: String, cityTableId :Number,   stateTableId :Number, countryTableId :Number,  id:any) => {
    const bodyParamter = {
        zipCode,
        cityTableId,
        stateTableId,
        countryTableId,
        tCode: Date.now().toString(),
        cityStatus : 1,
        updatedBy
    };
    return axios.put(API_URL + "zipcode/"+id, bodyParamter, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}

const getPincodeById = async (id:any) => {

    return axios.get(API_URL + "zipcode/"+id, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))
        
 
}

const importPinCode = async (allCountry :any, allzipcode : any,  allCity :any, allState:any) =>{
   await axios.post(API_URL + "country/import/", {countryName : allCountry}, config);   
    await axios.post(API_URL + "state/import/", {stateName : allState}, config ).then(async ()=>{
        const chunkSize = 100; // Set the desired chunk size for each iteration
        const totalChunksCity = Math.ceil(allCity.length / chunkSize);        
        for (let c = 0; c < totalChunksCity; c++) {
        const startIndexCity = c * chunkSize;
        const endIndexCity = Math.min((c + 1) * chunkSize, allCity.length);
        const chunkDataCity = allCity.slice(startIndexCity, endIndexCity);
    
        // Create a request payload with the current chunk data
        const requestDataCity = {
            citys:chunkDataCity,
        };
        try {
            // Send the POST request to the server using Axios
             await axios.post(API_URL + "city/import/", requestDataCity, config )
            // Handle the response from the server if needed
        } catch (error) {
            // Handle errors if the request fails
            console.error(error);
        }
        } 
 
            const totalChunks = Math.ceil(allzipcode.length / chunkSize);        
            for (let i = 0; i < totalChunks; i++) {
            const startIndex = i * chunkSize;
            const endIndex = Math.min((i + 1) * chunkSize, allzipcode.length);
            const chunkData = allzipcode.slice(startIndex, endIndex);
        
            // Create a request payload with the current chunk data
            const requestData = {
                zipcode:chunkData,
            };
            try {
                // Send the POST request to the server using Axios
                 await axios.post(API_URL + "zipcode/import/", requestData, config )
                // Handle the response from the server if needed
            } catch (error) {
                // Handle errors if the request fails
                console.error(error);
            }
            }   

       
        
    })

    
    return "Employee Imported"   
}
//----------------------- Pincode End -----------------------
//----------------------- Zone -------------------------

const getZone = async () =>{
    return axios.get(API_URL + "zone/",  config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))
}
const deleteZone = async (id:Number) =>{   
        return axios.delete(API_URL + "zone/"+id,  config).then(
            (response) => {
                return response.data.message
            })
            .catch(error => console.log(error))
    
    }

const postZone = async (zonename: String) => {
        const bodyParamter = {
            zonename,
            tCode: Date.now().toString(),
            zoneStatus : 1,
            createdBy
                };
return axios.post(API_URL + "zone/register", bodyParamter, config).then(
(response) => {
    return response.data.message
})
.catch(error => console.log(error))
    
}

const putZone = async (zonename: String,  id:any) => {
    const bodyParamter = {
        zonename,
        tCode: Date.now().toString(),
        cityStatus : 1,
        updatedBy
    };
    return axios.put(API_URL + "zone/"+id, bodyParamter, config).then(
        (response) => {
            return response.data.message
        })
        .catch(error => console.log(error))

}

const getZoneById = async (id:any) => {

    return axios.get(API_URL + "zone/"+id, config).then(
        (response) => {
            return response.data.message
        }
    ).catch(error => console.log(error))
        
 
}
//----------------------- Zone End -----------------------


const locationService = {
  getCountry,  
  deleteCountry,
  postCountry,
  putCountry,
  getCountryById,

  getState,  
  deleteState,
  postState,
  putState,
  getStateById,

  getCity,  
  deleteCity,
  postCity,
  putCity,
  getCityById,

  getPincodeList,
  getPincode,  
  deletePincode,
  postPincode,
  putPincode,
  getPincodeById,

  getZone,  
  deleteZone,
  postZone,
  putZone,
  getZoneById,

  importPinCode
}

export default locationService;