import { NavLink } from 'react-router-dom'

const Outcontainerheader = () => {
  return (
    <div>

      <div className='row col-12' >

        <ul className="nav nav-pills col-12 " id="pills-tab" role="tablist">

          <NavLink className={({ isActive }) =>
            [
              "navbar-item inwordbtn",
              isActive ? "active-link" : null,
            ]
              .filter(Boolean)
              .join(" ")
          } to="/outwardloglist" >Outward Log Entry
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [
                "navbar-item inwordbtn",
                isActive ? "active-link" : null,
              ]
                .filter(Boolean)
                .join(" ")
            } to="/outwarddetailslist" >Outward Detail Entry
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [
                "navbar-item inwordbtn",
                isActive ? "active-link" : null,
              ]
                .filter(Boolean)
                .join(" ")
            } to="/outwardimport">Outward Import
              </NavLink>
          <NavLink
            className={({ isActive }) =>
              [
                "navbar-item inwordbtn",
                isActive ? "active-link" : null,
              ]
                .filter(Boolean)
                .join(" ")
            } to="/outwardmail" >Outward Email
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [
                "navbar-item inwordbtn",
                isActive ? "active-link" : null,
              ]
                .filter(Boolean)
                .join(" ")
            } to="/outwardmenifestlist" >Outward Manifest
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [
                "navbar-item inwordbtn",
                isActive ? "active-link" : null,
              ]
                .filter(Boolean)
                .join(" ")
            } to="/outwardstatus" >Outward Status
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [
                "navbar-item inwordbtn",
                isActive ? "active-link" : null,
              ]
                .filter(Boolean)
                .join(" ")
            } to="/outwardbulkupdate" >Update Bulk Status
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              [
                "navbar-item inwordbtn",
                isActive ? "active-link" : null,
              ]
                .filter(Boolean)
                .join(" ")
            } to="/uploadstatus" > Status Upload
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [
                "navbar-item inwordbtn",
                isActive ? "active-link" : null,
              ]
                .filter(Boolean)
                .join(" ")
            } to="/outwardtracking" >Outward Tracking
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              [
                "navbar-item inwordbtn",
                isActive ? "active-link" : null,
              ]
                .filter(Boolean)
                .join(" ")
            } to="/adminmateriallist" >Admin Material Out
          </NavLink>
        </ul>

      </div>
    </div>
  )
}

export default Outcontainerheader